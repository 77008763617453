.login-wrapper {
    min-height: 90vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #fafafa;
}

.login-inner {
    width: calc(100% - 2rem);
    min-width: 15rem;
    max-width: 20rem;
    margin: auto 1rem;
}

.login-card {
    width: 100%;
    background-color: white;
    padding: 1rem;
    border-radius: 4px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-title {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 1rem 0 2rem;
    text-align: center;
}

.link-bottom {
    margin-top: 1rem;
    text-align: right;
}

.link-button {
    margin: 0;
    padding: 0.5rem 0;
    border: 0;
    background-color: transparent;
    color: #ff6600;
    outline: none;
    font-size: 0.85rem;
    text-decoration: none;
}

.link-button:active {
    text-decoration: none;
}

.login-form input {
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline-color: #ccc;
}

.login-form input::placeholder {
    color: #ccc;
}

.login-form button {
    padding: 1rem;
    background-color: #ff6600;
    border: 0;
    border-radius: 4px;
    color: white;
    font-size: 1rem;
    font-weight: bold;
}

.error-message {
    background-color: #fff0e6;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}