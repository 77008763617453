.shop-topbar {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #fafafa;
    border-bottom: 1px solid #ccc;
}

.shop-container {
    position: relative;
    display: flex;
    margin-top: 3rem;
    background-color: #fafafa;
}

.logo {
    font-weight: bold;
    text-decoration: none;
    font-family: sans-serif;
    color: #ff6600;
    font-size: 1.4rem;
}

.shop-main {
    width: 100%;
    padding: 0 0 2rem;
}

.benefit-cards {
    margin-top: 2rem;
}

.inline-block {
    display: inline-block;
}

.gear-icon {
    vertical-align: bottom;
}

.benefit-cards-wrapper {
    padding: 0 1rem 1rem;
}

.card-top {
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
}

.card-name {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: bold;
    text-decoration: none;
    color: black;
}

.card-each-box {
    background-color: white;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
    border: 1px solid #eee;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.benefit-card-list {
    margin: 1rem 0 0;
    display: flex;
    align-items: center;
}

.benefit-card-array {
    display: inline-block;
}

.benefit-card-list p {
    margin-top: 0.5rem;
}

.card-promo {
    display: inline-block;
    color: white;
    background-color: #0072ff;
    padding: 5px 10px 5px 5px;
    border-radius: 0 10px 10px 0;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 0.8rem;
    vertical-align: top;
    margin-top: 16px;
    margin-left: -7px;
}

.benefit .card-promo {
    vertical-align: top;
    margin-top: 0;
    margin-left: -8px;
}

.card-desc {
    color: gray;
    font-size: 0.8rem;
    line-height: 1rem;
}

.logout-button {
    margin: 0 auto;
    display: inherit;
    padding: 1rem;
    background-color: #ccc;
    border: 0;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    width: calc(100% - 2rem);
    margin: 5rem 1rem;
}

.more-copy {
    padding: 1rem;
    line-height: 1.2rem;
    background-color: #eee;
    border-radius: 4px;
    display: block;
    margin-bottom: 5px;
}