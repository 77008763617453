html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
html, body {
	-webkit-font-smoothing: antialiased;
}
html {
    box-sizing: border-box;
}
body {
	line-height: 1;
	font-family: 'Roboto';
	color: #4d4d4d;
	background-color: #fafafa;
}
*, *:before, *:after {
    box-sizing: inherit;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* Global styles */
.pill-container {
	display: flex;
	flex-wrap: wrap;
	padding: 1.5rem 1rem 1.2rem;
	background-color: white;
	border-bottom: 1px solid #eee;
}

.pill {
	background-color: white;
	border: 1px solid #eee;
	border-radius: 4px;
	font-size: 1rem;
	line-height: 1.5rem;
	padding: 3px 1rem;
	margin: 5px;
	cursor: pointer;
}

.pill.selected {
	background-color: #ff8f45;
	color: white;
	border: 1px solid white;
	font-weight: bold;
}

.pill:focus {
	outline: 0;
}

.small {
	font-size: 0.8rem;
}

.link-color {
	color: #ff8f45;
}