.settings-wrapper {
    padding: 1rem;
}

.card-list {
    margin: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.your-cards-box {
    background-color: #fff0e6;
    padding: 1px 0;
    margin-top: 1rem;
    border-radius: 4px;
}

.remove-button {
    margin-left: 1rem;
    font-size: 0.7rem;
    padding: 0 0.5rem;
    line-height: 1.5rem;
    background: red;
    border: 0;
    color: white;
    cursor: pointer;
    border-radius: 3px;
}

.cta-button {
    margin-top: 1rem;
    width: 100%;
    display: block;
    background-color: #ff6600;
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
}